
import scoreHelper from '@/helpers/Score.helper';
import { Score, ScoreTimeFrame } from '@/models/Score';
import { computed, defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import ScoreBar from './ScoreBar.vue';

export default defineComponent({
  components: { ScoreBar },
  props: {
    score: { type: Object as PropType<Score>, required: true },
    timeframe: {
      type: String as PropType<ScoreTimeFrame>,
      default: () => {
        return ScoreTimeFrame.WEEKLY;
      },
    },
  },
  setup(props) {
    const { t, te } = useI18n();

    const measurementColor = computed(() => scoreHelper.measurementColor(props.score));
    const isRedColor = computed(() => measurementColor.value == 'red');
    const measurementUnit = computed(() => scoreHelper.measurementUnit(props.score));
    const scoreDescription = computed(() => {
      if (props.timeframe == ScoreTimeFrame.WEEKLY && te(`cgm-scores.description.${props.score.type}.average`)) {
        return t(`cgm-scores.description.${props.score.type}.average`, {
          timeframe: t(`cgm-scores.description.timeframe.${props.timeframe}`),
        });
      }
      if (te(`cgm-scores.description.${props.score.type}.absolute`)) {
        return t(`cgm-scores.description.${props.score.type}.absolute`, {
          timeframe: t(`cgm-scores.description.timeframe.${props.timeframe}`),
        });
      }
      return t(`cgm-scores.description.${props.score.type}`, {
        timeframe: t(`cgm-scores.description.timeframe.${props.timeframe}`),
      });
    });
    return { isRedColor, measurementColor, measurementUnit, scoreDescription };
  },
});
