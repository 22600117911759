
import { ScoreType } from '@/models/Score';
import { Chart, ChartType, Statistics } from '@/models/Statistics';
import { computed, defineComponent, PropType } from 'vue';
import scoreHelper from '@/helpers/Score.helper';
import ScoreCircular from '@/components/charts/ScoreCircular.vue';
import ScoreAccordion from '@/components/charts/ScoreAccordion.vue';
import HeadingComponent from '@/components/HeadingComponent.vue';
import EmptyState from '@/components/EmptyState.vue';

export default defineComponent({
  components: { ScoreCircular, ScoreAccordion, HeadingComponent, EmptyState },
  props: {
    title: {
      type: String,
      default: '',
    },
    statistics: {
      type: Object as PropType<Statistics | undefined>,
    },
  },
  setup(props) {
    const glucoseSerie = computed(
      () => props.statistics?.charts?.find((chart: Chart) => chart.type == ChartType.CGM_DAILY)?.series[0],
    );
    const efficiency = computed(() => props.statistics?.scores?.find((item: any) => item.type == ScoreType.EFFICIENCY));
    const minGlucose = computed(() =>
      props.statistics?.scores?.find((item: any) => item.type == ScoreType.MIN_GLUCOSE),
    );
    const maxGlucose = computed(() =>
      props.statistics?.scores?.find((item: any) => item.type == ScoreType.MAX_GLUCOSE),
    );
    const avgGlucose = computed(() =>
      props.statistics?.scores?.find((item: any) => item.type == ScoreType.AVG_GLUCOSE),
    );
    const coefficientOfVariation = computed(() =>
      props.statistics?.scores?.find((item: any) => item.type == ScoreType.COEFFICIENT_OF_VARIATION),
    );
    const timeInRange = computed(() =>
      props.statistics?.scores?.find((item: any) => item.type == ScoreType.TIME_IN_RANGE),
    );
    const fastingGlucose = computed(() =>
      props.statistics?.scores?.find((item: any) => item.type == ScoreType.FASTING_GLUCOSE),
    );
    return {
      scoreHelper,
      minGlucose,
      maxGlucose,
      efficiency,
      avgGlucose,
      coefficientOfVariation,
      timeInRange,
      fastingGlucose,
      glucoseSerie,
    };
  },
});
