
import HeadingComponent from '@/components/HeadingComponent.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import AccordionScoreList from '@/components/wearables/common/AccordionScoreList.vue';
import DaysPickerWithMonths from '@/components/wearables/common/DaysPickerWithMonths.vue';
import { Chart, ChartType } from '@/models/Statistics';
import { eventService } from '@/services/EventService';
import { measurementService } from '@/services/MeasurementService';
import { patientService } from '@/services/PatientService';
import { ServiceError } from '@/services/util/ServiceError';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import AverageHeartRateCard from '../common/AverageHeartRateCard.vue';
import MoreInfoCard from '../common/MoreInfoCard.vue';
import SleepChart from './SleepChart.vue';
import SleepDurationCard from './SleepDurationCard.vue';
import SleepPhases from './SleepPhases.vue';

export default defineComponent({
  components: {
    DaysPickerWithMonths,
    LoadingComponent,
    SleepChart,
    AccordionScoreList,
    SleepDurationCard,
    SleepPhases,
    AverageHeartRateCard,
    HeadingComponent,
    MoreInfoCard,
  },

  setup() {
    const route = useRoute();
    const patientId = Array.isArray(route.params.patientId) ? route.params.patientId[0] : route.params.patientId;
    const isLoading = ref(false);
    const glucoseStatistics = ref();
    const heartRateStatistics = ref();
    const sleepData = ref();
    const patient = ref();
    const { t } = useI18n();
    const tabsMenu = ref([
      { label: t(`wearables.sleepTab.title`), to: 'sleep' },
      { label: t(`wearables.activityTab.title`), to: 'activity' },
      { label: t(`wearables.nutritionTab.title`), to: 'nutrition' },
    ]);

    const chartStart = computed(() => (sleepData.value ? sleepData.value.date : ''));
    const chartEnd = computed(() => (sleepData.value ? sleepData.value.endDate : ''));

    onMounted(async () => {
      await getPatientData();
    });

    const onDayPickerChanged = (dayStart: string, dayEnd: string) => {
      if (dayStart == selectedStartDate.value && dayEnd == selectedEndDate.value) {
        return;
      }

      selectedStartDate.value = dayStart;
      selectedEndDate.value = dayEnd;

      fecthStatistics();
    };

    const glucoseSerie = computed(
      () => glucoseStatistics.value?.charts?.find((chart: Chart) => chart.type == ChartType.CGM_DAILY)?.series[0],
    );

    const selectedStartDate = ref('');
    const selectedEndDate = ref('');

    const fecthStatistics = async () => {
      isLoading.value = true;
      heartRateStatistics.value = undefined;
      glucoseStatistics.value = undefined;
      sleepData.value = undefined;
      await getDataSleep();
      if (sleepData.value) {
        await fetchStatisticsGlucose();
        await fetchStatisticsHeartRate();
      }
      isLoading.value = false;
    };

    const fetchStatisticsGlucose = async () => {
      const result = await measurementService.findGlucoseStatistics(
        patientId,
        sleepData.value.date,
        sleepData.value.endDate,
      );

      if (!(result instanceof ServiceError)) {
        glucoseStatistics.value = result;
      }
    };

    const fetchStatisticsHeartRate = async () => {
      const result = await measurementService.findHeartRateStatistics(
        patientId,
        sleepData.value.date,
        sleepData.value.endDate,
      );

      if (!(result instanceof ServiceError)) {
        heartRateStatistics.value = result;
      }
    };

    const getDataSleep = async () => {
      const result = await eventService.findSleepEventByEndDate(
        patientId,
        selectedStartDate.value,
        selectedEndDate.value,
      );

      if (!(result instanceof ServiceError)) {
        sleepData.value = result;
      }
    };

    const getPatientData = async () => {
      const result = await patientService.find(patientId);
      if (!(result instanceof ServiceError)) {
        patient.value = result;
      }
    };

    return {
      chartStart,
      chartEnd,
      glucoseStatistics,
      heartRateStatistics,
      glucoseSerie,
      isLoading,
      onDayPickerChanged,
      sleepData,
      patient,
      tabsMenu,
      route,
    };
  },
});
