import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "databar-wrapper" }
const _hoisted_2 = { class: "databar__scale" }
const _hoisted_3 = { class: "databar__scale-unit" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "databar__scale-unit" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["databar", ['databar--' + _ctx.scoreScale]])
    }, null, 2),
    (_ctx.scoreHasValue)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["databar-pointer", _ctx.pointerColor]),
          style: _normalizeStyle({ left: _ctx.leftStyle })
        }, null, 6))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        (_ctx.showLessThanMarker)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4))
          : _createCommentVNode("", true),
        _createTextVNode(_toDisplayString(_ctx.score.optimalValueMin), 1)
      ]),
      _createElementVNode("span", _hoisted_5, [
        _cache[0] || (_cache[0] = _createElementVNode("span", null, null, -1)),
        _createTextVNode(_toDisplayString(_ctx.score.optimalValueMax), 1)
      ])
    ])
  ]))
}