
import scoreHelper from '@/helpers/Score.helper';
import { Score, ScoreType } from '@/models/Score';
import { defineComponent } from '@vue/runtime-core';
import { computed, PropType } from 'vue';

export default defineComponent({
  props: {
    score: {
      type: Object as PropType<Score>,
      required: true,
    },
  },
  setup(props) {
    const minValue = computed(() => {
      if (props.score && props.score.optimalValueMin) {
        if (props.score.type == ScoreType.MIN_GLUCOSE) {
          return props.score.optimalValueMin - 20; //needed to visually adjust the gradient ranges
        }

        if (props.score.type == ScoreType.AVG_GLUCOSE || props.score.type == ScoreType.FASTING_GLUCOSE) {
          return props.score.optimalValueMin - 30; //needed to visually adjust the gradient ranges
        }

        if (props.score.type == ScoreType.MAX_GLUCOSE) {
          return props.score.optimalValueMin - 80; //needed to visually adjust the gradient ranges
        }

        if (props.score.type == ScoreType.TIME_IN_RANGE) {
          return props.score.optimalValueMin - 5; //needed to visually adjust the gradient ranges
        }
        return props.score.optimalValueMin;
      }
      return 0;
    });

    const maxValue = computed(() => {
      if (props.score && props.score.optimalValueMax) {
        if (props.score.type == ScoreType.AVG_GLUCOSE || props.score.type == ScoreType.FASTING_GLUCOSE) {
          return props.score.optimalValueMax + 20; //needed to visually adjust the gradient ranges
        }
        if (props.score.type == ScoreType.MIN_GLUCOSE || props.score.type == ScoreType.TIME_IN_RANGE) {
          return props.score.optimalValueMax + 5; //needed to visually adjust the gradient ranges
        }
        if (props.score.type == ScoreType.MAX_GLUCOSE) {
          return props.score.optimalValueMax + 100; //needed to visually adjust the gradient ranges
        }
        if (props.score.type == ScoreType.COEFFICIENT_OF_VARIATION) {
          return props.score.optimalValueMax + 5; //needed to visually adjust the gradient ranges
        }
        return props.score.optimalValueMax;
      }
      return 10;
    });

    const showLessThanMarker = computed(() => {
      if (props.score.type == ScoreType.COEFFICIENT_OF_VARIATION) {
        return false;
      }
      return true;
    });

    const scoreHasValue = computed(() => {
      if (!props.score.value) {
        return false;
      }
      return true;
    });

    const percentageValue = computed(() => {
      if (!props.score.value) {
        return 0;
      }

      let raw = ((props.score.value - minValue.value) / (maxValue.value - minValue.value)) * 100;

      if (raw < 0) {
        raw = 0;
      }
      if (raw > 100) {
        raw = 100;
      }

      return raw;
    });

    const scoreScale = computed(() => {
      switch (props.score.type) {
        case ScoreType.TIME_IN_RANGE:
          return 'tir';
        case ScoreType.MIN_GLUCOSE:
          return 'min';
        case ScoreType.MAX_GLUCOSE:
          return 'max';
        case ScoreType.COEFFICIENT_OF_VARIATION:
          return 'cv';
        case ScoreType.FASTING_GLUCOSE:
          return 'fasting';
        default:
          return 'avg';
      }
    });

    const pointerColor = computed(() => {
      switch (props.score.type) {
        case ScoreType.MIN_GLUCOSE:
          return scoreHelper.glucoseMinColor(props.score.value);
        case ScoreType.MAX_GLUCOSE:
          return scoreHelper.glucoseMaxColor(props.score.value);
        case ScoreType.AVG_GLUCOSE:
          return scoreHelper.glucoseAvgColor(props.score.value);
        case ScoreType.COEFFICIENT_OF_VARIATION:
          return scoreHelper.coefficientOfVariationColor(props.score.value);
        case ScoreType.TIME_IN_RANGE:
          return scoreHelper.timeInRangeColor(props.score.value);
        case ScoreType.FASTING_GLUCOSE:
          return scoreHelper.fastingGlucosegColor(props.score.value);
        default:
          return scoreHelper.glucoseAvgColor(props.score.value);
      }
    });

    const leftStyle = computed(() => `calc(${percentageValue.value}% - 10px)`);

    return {
      percentageValue,
      leftStyle,
      scoreScale,
      scoreHasValue,
      pointerColor,
      minValue,
      showLessThanMarker,
      maxValue,
    };
  },
});
