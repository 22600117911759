import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-average-hr-container" }
const _hoisted_2 = { class: "c-average-hr" }
const _hoisted_3 = { class: "c-average-hr__value" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "c-average-hr__label" }
const _hoisted_6 = { class: "c-average-max-min-hr" }
const _hoisted_7 = { class: "c-max-min-hr-container" }
const _hoisted_8 = { class: "c-min-max-hr__value" }
const _hoisted_9 = { class: "c-min-max-hr__label" }
const _hoisted_10 = { class: "c-max-min-hr-container" }
const _hoisted_11 = { class: "c-min-max-hr__value" }
const _hoisted_12 = { class: "c-min-max-hr__label" }

import { ref, computed, PropType } from 'vue';
import Statistics from '@/models/Statistics';
import HeadingComponent from '@/components/HeadingComponent.vue';
import { Score, ScoreType } from '@/models/Score';
const emptyValue = '--';

export default /*@__PURE__*/_defineComponent({
  __name: 'AverageHeartRateCard',
  props: {
  title: {
    type: String,
    default: '',
  },
  heartRateStatistics: {
    type: Object as PropType<Statistics>,
  },
},
  setup(__props) {

const iconsUrlBase = ref(window.location.origin + '/assets/icon/wearables/');
const props = __props;

const minValueScore = computed(() => {
  if (props.heartRateStatistics?.scores) {
    return (
      props.heartRateStatistics?.scores?.find((score: Score) => score.type == ScoreType.MIN_VALUE)?.value?.toFixed(0) ??
      emptyValue
    );
  }
  return emptyValue;
});
const maxValueScore = computed(() => {
  if (props.heartRateStatistics?.scores) {
    return (
      props.heartRateStatistics?.scores?.find((score: Score) => score.type == ScoreType.MAX_VALUE)?.value?.toFixed(0) ??
      emptyValue
    );
  }
  return emptyValue;
});

const avgValueScore = computed(() => {
  if (props.heartRateStatistics?.scores) {
    return (
      props.heartRateStatistics?.scores
        ?.find((score: Score) => score.type === ScoreType.AVG_VALUE)
        ?.value?.toFixed(0) ?? emptyValue
    );
  }
  return emptyValue;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(HeadingComponent, {
      title: __props.title,
      "sub-heading": true,
      divider: false
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(avgValueScore.value) + " " + _toDisplayString(_ctx.$t('wearables.averageHeartRate.ppm')), 1),
        _createElementVNode("img", {
          src: iconsUrlBase.value + 'icon-hr.svg',
          alt: "",
          class: "c-average-hr__icon"
        }, null, 8, _hoisted_4),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('wearables.averageHeartRate.ppmAverage')), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _cache[0] || (_cache[0] = _createElementVNode("i", {
            class: "icon-heart_fill c-max-min-hr__heart-icon",
            "aria-hidden": "true"
          }, null, -1)),
          _createElementVNode("p", _hoisted_8, _toDisplayString(maxValueScore.value) + " " + _toDisplayString(_ctx.$t('wearables.averageHeartRate.ppm')), 1),
          _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('wearables.averageHeartRate.max')), 1)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _cache[1] || (_cache[1] = _createElementVNode("i", {
            class: "icon-heart_fill c-max-min-hr__heart-icon",
            "aria-hidden": "true"
          }, null, -1)),
          _createElementVNode("p", _hoisted_11, _toDisplayString(minValueScore.value) + " " + _toDisplayString(_ctx.$t('wearables.averageHeartRate.ppm')), 1),
          _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('wearables.averageHeartRate.min')), 1)
        ])
      ])
    ])
  ], 64))
}
}

})