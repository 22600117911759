import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "c-sleep-phases" }
const _hoisted_2 = { class: "c-sleep-phases__container" }
const _hoisted_3 = { class: "c-sleep-phases__container__progress-bar" }
const _hoisted_4 = { class: "c-sleep-phases__container__legend" }
const _hoisted_5 = { class: "c-sleep-phases__container__legend__item__icon" }
const _hoisted_6 = { class: "c-sleep-phases__container__legend__item__text" }
const _hoisted_7 = { class: "c-sleep-phases__container__legend__item__text__label" }
const _hoisted_8 = { class: "c-sleep-phases__container__legend__item__text__description" }
const _hoisted_9 = { class: "c-sleep-phases__container__legend__item__value" }

import HeadingComponent from '@/components/HeadingComponent.vue';
import { PropType, computed } from 'vue';
import MultiProgressBar from '@/components/common/MultiProgressBar.vue';
import moment from 'moment';
import { EventDto } from '@/models/Event';
import { PhaseType } from '@/models/Phases';
import { MultiProgressBarData } from '@/models/ChartConfig';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'SleepPhases',
  props: {
  sleepData: {
    type: Object as PropType<EventDto> | undefined,
  },
},
  setup(__props) {

const { t } = useI18n();

const props = __props;

const sleepPhases = computed(() => {
  if (props.sleepData?.eventPhases) {
    const totalDuration = props.sleepData.eventPhases.reduce((sum: number, phaseTime: any) => sum + phaseTime.time, 0);
    const phasesArray: MultiProgressBarData[] = props.sleepData.eventPhases.map((phase) => {
      const duration = moment.duration(phase.time, 'seconds');
      const formattedTime = `${Math.floor(duration.asHours())}h${duration.minutes()}m`;

      return {
        percentage: parseFloat(((phase.time / totalDuration) * 100).toFixed(2)),
        color: getPhaseColor(phase.phase),
        labelLegend: t(`wearables.sleepPashes.${phase.phase}`),
        valueLegend: `${parseFloat(((phase.time / totalDuration) * 100).toFixed(2))}%`,
        time: formattedTime,
      };
    });

    return phasesArray;
  }
  return getEmptyState();
});

const getEmptyState = () => {
  let emptyState: MultiProgressBarData[] = Object.values(PhaseType).map((phase: PhaseType) => ({
    percentage: 0,
    color: getPhaseColor(phase),
    labelLegend: t(`wearables.sleepPashes.${phase}`),
    valueLegend: '-- %',
    time: '--',
  }));
  return emptyState;
};

const getPhaseColor = (phase: string) => {
  switch (phase) {
    case PhaseType.AWAKE:
      return '#F5A500';
    case PhaseType.LIGHT:
      return '#00B833';
    case PhaseType.DEEP:
      return '#89C0F2';
    case PhaseType.REM:
      return '#FFD15B';
    default:
      return '#234D76';
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(HeadingComponent, {
      title: _ctx.$t('wearables.sleepPashes.sleepPashesTitle'),
      "sub-heading": true,
      divider: false
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(MultiProgressBar, {
            barListValues: sleepPhases.value,
            showLegend: true
          }, null, 8, ["barListValues"]),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sleepPhases.value, (phase, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "c-sleep-phases__container__legend__item"
              }, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("i", {
                    class: "icon-heart_fill",
                    style: _normalizeStyle('color:' + phase.color + ';')
                  }, null, 4)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("p", _hoisted_7, _toDisplayString(phase.labelLegend), 1),
                  _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('wearables.sleepPashes.average')), 1)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("p", null, _toDisplayString(phase.time), 1)
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ], 64))
}
}

})