import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-more-info-card" }
const _hoisted_2 = { class: "c-more-info-card__img" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "c-more-info-card__description" }
const _hoisted_5 = { class: "c-more-info-card__text" }
const _hoisted_6 = { class: "p-button p-button-info p-button-medium" }

import { ref } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'MoreInfoCard',
  setup(__props) {

const iconsUrlBase = ref(window.location.origin + '/assets/icon/wearables/');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: iconsUrlBase.value + 'more-info.svg',
        alt: "",
        class: "c-more-info-card__icon"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('wearables.moreInfo.moreInfoText')), 1),
      _createElementVNode("button", _hoisted_6, _toDisplayString(_ctx.$t('wearables.moreInfo.moreInfoButton')), 1)
    ])
  ]))
}
}

})