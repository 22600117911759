enum PhaseType {
  DEEP = 'DEEP',
  LIGHT = 'LIGHT',
  REM = 'REM',
  AWAKE = 'AWAKE',
}

interface Phases {
  endDate: string;
  phase: PhaseType;
  startDate: string;
  time: number;
}

export { Phases, PhaseType };
