import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-sleep-duration-container" }
const _hoisted_2 = { class: "c-sleep__item" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "c-sleep__value c-sleep__value--bold" }
const _hoisted_5 = { class: "c-sleep__item" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "c-sleep__value c-sleep__value--bold" }
const _hoisted_8 = { class: "c-sleep__item" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "c-sleep__value c-sleep__value--bold" }
const _hoisted_11 = { class: "c-sleep__item" }
const _hoisted_12 = ["src"]

import { ref, computed, PropType } from 'vue';
import HeadingComponent from '@/components/HeadingComponent.vue';
import dateFormat from '@/helpers/DateFormat.helper';
import moment from 'moment';
import { EventDto } from '@/models/Event';

const emptyState = '--';


export default /*@__PURE__*/_defineComponent({
  __name: 'SleepDurationCard',
  props: {
  sleepData: {
    type: Object as PropType<EventDto> | undefined,
  },
},
  setup(__props) {

const iconsUrlBase = ref(window.location.origin + '/assets/icon/wearables/');
const props = __props;

const startSleep = computed(() => {
  if (props.sleepData?.date) {
    return moment(dateFormat.utcToUserTimezone(props.sleepData.date)).format('H:mm A');
  }
  return emptyState;
});

const endSleep = computed(() => {
  if (props.sleepData?.endDate) {
    return moment(dateFormat.utcToUserTimezone(props.sleepData.endDate)).format('hh:mm A');
  }
  return emptyState;
});

const durationSleep = computed(() => {
  if (props.sleepData?.date && props.sleepData?.endDate) {
    const start = moment(props.sleepData.date);
    const end = moment(props.sleepData.endDate);
    const duration = moment.duration(end.diff(start)).asHours();

    const hours = Math.floor(duration);
    const minutes = Math.round((duration - hours) * 60);

    return `${hours}h ${minutes}m`;
  }
  return emptyState;
});

const qualitySleep = computed(() => {
  if (props.sleepData?.quality) {
    return props.sleepData.quality + '%';
  }

  return emptyState;
});

const getColorQuality = computed(() => {
  const quality = props.sleepData?.quality;
  if (!quality) return;

  if (quality && quality <= 60) {
    return '#C90000';
  }
  if (quality && quality < 80) {
    return '#F5A500';
  }

  return '#00B833';
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(HeadingComponent, {
      title: _ctx.$t('wearables.sleepDuration.sleepDurationTitle'),
      "sub-heading": true,
      divider: false
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          src: iconsUrlBase.value + 'icon-bed.svg',
          alt: ""
        }, null, 8, _hoisted_3),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('wearables.sleepDuration.sleepStart')), 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString(startSleep.value), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("img", {
          src: iconsUrlBase.value + 'icon-bell.svg',
          alt: ""
        }, null, 8, _hoisted_6),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('wearables.sleepDuration.sleepEnd')), 1),
        _createElementVNode("span", _hoisted_7, _toDisplayString(endSleep.value), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("img", {
          src: iconsUrlBase.value + 'icon-moon.svg',
          alt: ""
        }, null, 8, _hoisted_9),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('wearables.sleepDuration.sleepDuration')), 1),
        _createElementVNode("span", _hoisted_10, _toDisplayString(durationSleep.value), 1)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("img", {
          src: iconsUrlBase.value + 'icon-pillow.svg',
          alt: ""
        }, null, 8, _hoisted_12),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('wearables.sleepDuration.sleepQuality')), 1),
        _createElementVNode("span", {
          class: _normalizeClass(["c-sleep__value", {
          'c-sleep__value--bold': qualitySleep.value !== emptyState,
        }]),
          style: _normalizeStyle('color:' + getColorQuality.value + ';')
        }, _toDisplayString(qualitySleep.value), 7)
      ])
    ])
  ], 64))
}
}

})