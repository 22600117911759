import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "progress-container"
}
const _hoisted_2 = {
  key: 1,
  class: "progress-container"
}
const _hoisted_3 = {
  key: 2,
  class: "legend-container"
}
const _hoisted_4 = { class: "legend-value" }

import { MultiProgressBarData } from '@/models/ChartConfig';
import { PropType, computed } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'MultiProgressBar',
  props: {
  barListValues: {
    type: Object as PropType<MultiProgressBarData[]>,
    required: true,
  },
  showLegend: {
    type: Boolean,
    required: false,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const showEmptyState = computed(() => {
  const totalDuration = props.barListValues.reduce(
    (sum: number, phaseTime: MultiProgressBarData) => sum + phaseTime.percentage,
    0,
  );
  return totalDuration === 0;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (__props.barListValues && __props.barListValues.length > 0 && !showEmptyState.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.barListValues, (barValue, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "progress-item",
              style: _normalizeStyle('width:' + barValue.percentage + '%; background-color:' + barValue.color + ';')
            }, null, 4))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
          _createElementVNode("div", {
            class: "progress-item",
            style: {"width":"100%","background-color":"#e0e0e0"}
          }, null, -1)
        ]))),
    (__props.showLegend)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.barListValues, (barValue, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "legend-item"
            }, [
              _createElementVNode("span", {
                class: "legend-icon icon-dot_04_l",
                style: _normalizeStyle('color:' + barValue.color + ';')
              }, null, 4),
              _createElementVNode("span", _hoisted_4, _toDisplayString(barValue.valueLegend) + " " + _toDisplayString(barValue.labelLegend), 1)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})