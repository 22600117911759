import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "l-container" }
const _hoisted_2 = { class: "l-row--valign days_picker" }
const _hoisted_3 = { class: "p-grid--wearables-sleep-row" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "p-grid--wearables-sleep-column" }
const _hoisted_6 = { class: "card" }
const _hoisted_7 = { class: "card" }
const _hoisted_8 = { class: "card" }
const _hoisted_9 = { class: "p-grid--wearables-sleep-column" }
const _hoisted_10 = { class: "card" }
const _hoisted_11 = { class: "card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_DaysPickerWithMonths = _resolveComponent("DaysPickerWithMonths")!
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!
  const _component_SleepChart = _resolveComponent("SleepChart")!
  const _component_SleepDurationCard = _resolveComponent("SleepDurationCard")!
  const _component_AccordionScoreList = _resolveComponent("AccordionScoreList")!
  const _component_MoreInfoCard = _resolveComponent("MoreInfoCard")!
  const _component_SleepPhases = _resolveComponent("SleepPhases")!
  const _component_AverageHeartRateCard = _resolveComponent("AverageHeartRateCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeadingComponent, {
      title: _ctx.$t(_ctx.route.meta.breadcrumb ? _ctx.route.meta.breadcrumb[0].label : ''),
      "heading-icon": "icon-heart",
      tabs: _ctx.tabsMenu
    }, null, 8, ["title", "tabs"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.patient)
          ? (_openBlock(), _createBlock(_component_DaysPickerWithMonths, {
              key: 0,
              "initial-day": _ctx.patient.creationDate,
              onOnDayPickerChanged: _ctx.onDayPickerChanged
            }, null, 8, ["initial-day", "onOnDayPickerChanged"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadingComponent, {
            key: 0,
            spinnerColor: "lavender-700"
          }))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["p-grid--wearables-sleep-general", { 'p-grid--wearables-sleep-general--loading': _ctx.isLoading }])
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_SleepChart, {
                  chartStart: _ctx.chartStart,
                  chartEnd: _ctx.chartEnd,
                  "sleep-data": _ctx.sleepData,
                  "glucose-statistics": _ctx.glucoseStatistics,
                  "heart-rate-statistics": _ctx.heartRateStatistics
                }, null, 8, ["chartStart", "chartEnd", "sleep-data", "glucose-statistics", "heart-rate-statistics"])
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_SleepDurationCard, { sleepData: _ctx.sleepData }, null, 8, ["sleepData"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_AccordionScoreList, {
                  title: _ctx.$t('wearables.sleepTab.glucoseDaily'),
                  statistics: _ctx.glucoseStatistics
                }, null, 8, ["title", "statistics"])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_MoreInfoCard)
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_SleepPhases, { sleepData: _ctx.sleepData }, null, 8, ["sleepData"])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_AverageHeartRateCard, {
                  title: _ctx.$t('wearables.averageHeartRate.averageHeartRateTitleSleep'),
                  heartRateStatistics: _ctx.heartRateStatistics
                }, null, 8, ["title", "heartRateStatistics"])
              ])
            ])
          ], 2))
    ])
  ], 64))
}