
import EmptyState from '@/components/EmptyState.vue';
import HeadingComponent from '@/components/HeadingComponent.vue';
import MultiChart from '@/components/wearables/common/MultiChart.vue';
import { EventDto } from '@/models/Event';
import { Chart, ChartType } from '@/models/Statistics';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  components: { MultiChart, HeadingComponent, EmptyState },
  props: {
    chartStart: {
      type: String,
      required: true,
    },
    chartEnd: {
      type: String,
      required: true,
    },
    sleepData: {
      type: Object as PropType<EventDto> | undefined,
      required: true,
    },
    glucoseStatistics: {
      type: Object,
    },
    heartRateStatistics: {
      type: Object,
    },
  },
  setup(props) {
    const chartId = 'sleep-glucose-hr-chart';

    const glucoseSerie = computed(
      () => props.glucoseStatistics?.charts?.find((chart: Chart) => chart.type == ChartType.CGM_DAILY)?.series[0],
    );
    const heartRateSerie = computed(
      () => props.heartRateStatistics?.charts?.find((chart: Chart) => chart.type == ChartType.HEART_RATE)?.series[0],
    );

    return {
      glucoseSerie,
      heartRateSerie,
      chartId,
    };
  },
});
